import {Show} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNil} from 'ramda';

import {parseDate, suffixTestId, TestIdProps} from 'shared';

import {Alert} from '../../../../components/Alert/Alert';
import {useGetVinDecoderData} from '../../../../hooks/useGetVinDecoderData';
import i18n from '../../../../i18n/i18n';

export function DecodedAlert(props: TestIdProps) {
  const {decoder} = useGetVinDecoderData();
  const formatDateTime = useDateTimeFormatter();
  return (
    <Show when={isNotNil(decoder?.created)}>
      <Alert
        icon="calendar"
        iconColor="accent.dark"
        data-testid={suffixTestId('createdInfo', props)}
      >
        <strong>
          {i18n.t('entity.decoder.labels.createdOn', {
            date: formatDateTime('dateMedium', parseDate(decoder?.created ?? '')),
          })}
        </strong>
      </Alert>
    </Show>
  );
}
